



































































































































































































































































































































































































































































import EmptyStateContainer from '@/commoncomponents/EmptyStateContainer.vue';
import { ScreenText } from '@/lang/ScreenText';
import { Component, Vue } from 'vue-property-decorator';
import payments from '@/store/modules/Payments/module';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BouncingPreloader from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import { ConvenienceFeeGetResponse, OfflinePaymentsOption, Discount, OnboardingStatus, PaymentPlan } from '@/Model/payments/types';
import CardComponent from '@/commoncomponents/CardComponent.vue';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import STRIPE_CONSTANTS from '@/constants/StripeConst';
import StorePolicyControls from '@/components/accountDetailsComponent/accountDataTabs/payments/StorePolicyControls.vue';
import NotificationBox from '@/commoncomponents/NotificationBox.vue';
import CustomizeFee from '@/components/accountDetailsComponent/accountDataTabs/payments/CustomizeFee.vue';
import FeeDetails from '@/components/accountDetailsComponent/accountDataTabs/payments/FeeDetails.vue';
import OfflinePaymentsDetail from '@/components/accountDetailsComponent/accountDataTabs/payments/OfflinePaymentsDetail.vue';
import DiscountConfiguration from '@/components/accountDetailsComponent/accountDataTabs/payments/DiscountConfiguration.vue';
import OfflinePayments from '@/components/accountDetailsComponent/accountDataTabs/payments/OfflinePayments.vue';
import { getOfflinePaymentsOptionList } from '@/services/payments/api';
import BillingMethodConfiguration from '@/components/accountDetailsComponent/accountDataTabs/payments/BillingMethodConfiguration.vue';
import { ToastType } from '@/Model/toastType';
import APP_CONST from '@/constants/AppConst';
import DiscountTable from '@/components/accountDetailsComponent/accountDataTabs/payments/DiscountTable.vue';
import paymentsModule from '@/store/modules/Payments/module';
import PaymentPlanDetail from '@/components/accountDetailsComponent/accountDataTabs/payments/PaymentPlanDetail.vue';

@Component({
  components: {
    BouncingPreloader,
    CardComponent,
    EmptyStateContainer,
    NotificationBox,
    ProgressButton,
    StorePolicyControls,
    CustomizeFee,
    FeeDetails,
    OfflinePayments,
    OfflinePaymentsDetail,
    DiscountConfiguration,
    DiscountTable,
    BillingMethodConfiguration,
    PaymentPlanDetail
  }
})

export default class Payments extends Vue {

  isValidForProgressButton = true;
  screenText = new ScreenText();
  public STRIPE_CONST = STRIPE_CONSTANTS;
  isCustomizeFeeVisible = false;
  isOfflinePaymentsVisible = false;
  isLoadingOfflinePayments = false;
  selectedOfflinePaymentsOption: OfflinePaymentsOption | null = null;
  showOfflinePaymentsSpinner = false;
  isOfflinePaymentsEnabled = false;
  isAutomaticDiscountEnabled = false;
  paymentPlanFlag = false;

  showCustomFeeForm() {
    this.isCustomizeFeeVisible = true;
  }

  showOfflinePayments() {
    this.isOfflinePaymentsVisible = true;
  }

  showBillingMethodForm() {
    paymentsModule.setIsBillingMethodMenuOpen(true);
  }

  showAutomaticDiscount() {
    paymentsModule.setIsConfiguratioDiscountMenuOpen(true);
  }

  get discountListComputed() {
    return paymentsModule.discountList;
  }

  get isConfigurationDiscountMenuOpenComputed() {
    return paymentsModule.isConfigurationDiscountMenuOpen;
  }

  get discounts():Discount[] {
    return APP_CONST.MOCKED_DISCOUNTS;
  }

  get merchantAccountSetupTitle(): string {
    return payments.stripeAccountResponse && payments.stripeAccountResponse.onboardingStatus === OnboardingStatus.InProgress
      ? this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_SETUP_CONTINUE_TITLE')
      : this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_SETUP_START_TITLE');
  }

  get merchantAccountSetupSubtitle(): string {
    return payments.stripeAccountResponse && payments.stripeAccountResponse.onboardingStatus === OnboardingStatus.InProgress
      ? this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_SETUP_CONTINUE_SUBTITLE')
      : this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_SETUP_START_SUBTITLE');
  }

  get merchantAccountSetupButtonText(): string {
    return payments.stripeAccountResponse && payments.stripeAccountResponse.onboardingStatus === OnboardingStatus.InProgress
      ? this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_SETUP_CONTINUE_BUTTON_TEXT')
      : this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_SETUP_START_BUTTON_TEXT');
  }

  get customFee(): ConvenienceFeeGetResponse | null {
    return payments.customFee;
  }

  get paymentPlan(): PaymentPlan | null {
    return payments.paymentPlan;
  }

  get merchantAccountRetryButtonText(): string {
    return this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_SETUP_RETRY_BUTTON_TEXT');
  }

  get merchantAccountErrorText(): string {
    return this.screenText.getScreenText('PAYMENTS_MERCHANT_ACCOUNT_UNAVAILABLE');
  }

  get continueMerchantSetupTitle(): string {
    return this.screenText.getScreenText('CONTINUE_MERCHANT_ACCOUNT_SETUP');
  }

  get finishEcommerceAccountSetupSub(): string {
    return this.screenText.getScreenText('FINISH_YOUR_ECOMMERCE_ACCOUNT_SETUP');
  }

  get manageOnlinePaymentSettingsTitle(): string {
    return this.screenText.getScreenText('MANAGE_ONLINE_PAYMENT_SETTINGS');
  }

  get manageSettingsDesc(): string {
    return this.screenText.getScreenText('MANAGE_SETTINGS_DESC');
  }

  get buttonDisplaySettings(): string {
    return this.screenText.getScreenText('BTN_SETTINGS');
  }

  get payoutTitle(): string {
    return this.screenText.getScreenText('PAYOUT');
  }

  get payoutDesc(): string {
    return this.screenText.getScreenText('PAYOUT_DESC');
  }

  get buttonPayout(): string {
    return this.screenText.getScreenText('BTN_PAYOUT');
  }

  get policyTitle(): string {
    return this.screenText.getScreenText('POLICY');
  }

  get policyDesc(): string {
    return this.screenText.getScreenText('POLICY_DESC');
  }

  get taxTitle(): string {
    return this.screenText.getScreenText('TAX');
  }

  get optional(): string {
    return this.screenText.getScreenText('OPTIONAL');
  }

  get taxDesc(): string {
    return this.screenText.getScreenText('TAX_DESC');
  }

  get buttonTax(): string {
    return this.screenText.getScreenText('BTN_TAX');
  }

  get couponTitle(): string {
    return this.screenText.getScreenText('COUPON');
  }

  get couponDesc(): string {
    return this.screenText.getScreenText('COUPON_DESC');
  }

  get couponInfoBoxText(): string {
    return this.screenText.getScreenText('COUPON_INFO');
  }

  get buttonCoupon(): string {
    return this.screenText.getScreenText('BTN_COUPON');
  }

  get billingMethodTitle(): string {
    return this.screenText.getScreenText('BILLING_METHODS');
  }

  get billingMethodDescription(): string {
    return this.screenText.getScreenText('BILLING_METHODS_DESCRIPTION');
  }

  get addPaymentPlanButtonText(): string {
    return this.screenText.getScreenText('ADD_PAYMENT_PLANS');
  }

  //TODO: this billing option is fixed, but it should be dynamic as long as the other ones created by the users
  get payInFullText(): string {
    return this.screenText.getScreenText('PAY_IN_FULL');
  }

  get feeCardTitle(): string {
    return this.screenText.getScreenText('FEE_CARD_TITLE');
  }

  get feeCardDesc(): string {
    return this.screenText.getScreenText('FEE_CARD_DESC');
  }

  get addFeeButtonText(): string {
    return this.screenText.getScreenText('BTN_ADD_FEE');
  }

  get reportingTitle(): string {
    return this.screenText.getScreenText('REPORTING_TITLE');
  }

  get reportingDesc(): string {
    return this.screenText.getScreenText('REPORTING_DESC');
  }

  get reportingExample(): string {
    return this.screenText.getScreenText('REPORTING_EXAMPLE');
  }

  get buttonDisplayReports(): string {
    return this.screenText.getScreenText('BTN_REPORTS');
  }

  get isCurrentlyLoading(): boolean {
    return payments.isFetchingOnboardingStatus;
  }

  get shouldShowMerchantAccountError(): boolean {
    return payments.didOnboardingLinkFetchFail || payments.didOnboardingStatusFetchFail;
  }

  get hasOnBoardingCompleted(): boolean {
    if (payments.stripeAccountResponse) {
      return payments.stripeAccountResponse.onboardingStatus === OnboardingStatus.Completed;
    }
    return false;
  }

  get shouldDisplayBillingMethodSpinner(): boolean {
    return payments.isFetchingPaymentPlan || payments.isUpdatingPaymentPlan || payments.isCreatingPaymentPlan;
  }

  get shouldDisplayCustomFeeSpinner(): boolean {
    return payments.isLoadingCustomFee || payments.isSavingCustomFee;
  }

  get shouldDisplayAutomaticDiscountSpinner(): boolean {
    return payments.isFetchingAutomaticDiscounts || payments.isDeletingDiscount || payments.isUpdatingDiscount;
  }

  get accountId(): number {
    const accountIdCookie = APP_UTILITIES.getCookie('accountId');
    const accountId = accountIdCookie
      ? Number.parseInt(accountIdCookie)
      : 0;
    return accountId;
  }

  translateLoadingToProgressButtonState(): number {
    let buttonState = 0;

    if (payments.isFetchingOnboardingLink === false && payments.didOnboardingLinkFetchFail === false) {
      buttonState = 200;
    }
    else if (payments.isFetchingOnboardingLink === false && payments.didOnboardingLinkFetchFail) {
      buttonState = 400;
    }
    return buttonState;
  }

  async created() {
    const offlinePaymentsFlag = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.customPayments);
    const automaticDiscountFlag = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.automaticDiscounts);
    this.paymentPlanFlag = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.paymentPlans);

    this.isOfflinePaymentsEnabled = offlinePaymentsFlag;
    this.isAutomaticDiscountEnabled = automaticDiscountFlag;
    if (this.isOfflinePaymentsEnabled) {
      this.getOfflinePaymentsMethods();
    }
    if (this.isAutomaticDiscountEnabled) {
      payments.fetchAutomaticDiscountsByAccount();
    }

    if(this.paymentPlanFlag) {
      payments.fetchPaymentPlan();
    }

    payments.resetOnboardingState();
    this.retrieveOnboardingStatusAndFee();
    this.STRIPE_CONST = STRIPE_CONSTANTS;

  }

  async refreshOnSaveOfflinePayments() {
    const result = await getOfflinePaymentsOptionList(this.accountId);
    this.selectedOfflinePaymentsOption = result.data[0];
    // because of the current setup on the app the reactivity on the child components is not behaving as expected so we need to trigger the rerender manually
    this.$forceUpdate();
    this.isOfflinePaymentsVisible = false;
  }

  async refreshOfflinePayments() {
    const result = await getOfflinePaymentsOptionList(this.accountId);
    this.selectedOfflinePaymentsOption = result.data[0];
    // because of the current setup on the app the reactivity on the child components is not behaving as expected so we need to trigger the rerender manually
    this.$forceUpdate();
  }

  async refreshPaymentPlan() {
    await paymentsModule.fetchPaymentPlan();
    // because of the current setup on the app the reactivity on the child components is not behaving as expected so we need to trigger the rerender manually
    this.$forceUpdate();
  }


  async getOfflinePaymentsMethods() {
    try {
      this.isLoadingOfflinePayments = true;
      const result = await getOfflinePaymentsOptionList(this.accountId);
      this.selectedOfflinePaymentsOption = result.data[0];
    }
    catch {
      APP_UTILITIES.showToastMessage('Unexpected Error!', ToastType.Error);
    }
    finally {
      this.isLoadingOfflinePayments = false;
    }
  }

  async retrieveOnboardingStatusAndFee() {
    await payments.getOnboardingStatus(this.accountId);
    if (this.hasOnBoardingCompleted) {
      payments.retrieveCustomFee();
    }
  }

  async retrieveOnboardingLink() {

    this.isValidForProgressButton = false;

    const onboardingUrl = await payments.getOnboardingLink(this.accountId);
    if (onboardingUrl) {
      window.location.href = onboardingUrl;
    }

    this.isValidForProgressButton = true;
  }

  navigateTo(link: string) {
    const route = STRIPE_CONSTANTS.URLS[link as keyof STRIPE_CONSTANTS];
    window.location.assign(route);
  }
}
