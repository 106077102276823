



























































































































import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText'
import OffCanvas from '@/commoncomponents/OffCanvas.vue'
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue'
import { CurrencyInput, CurrencyInputOptions } from 'vue-currency-input'
import APP_CONST from '@/constants/AppConst'
import APP_UTILITIES from '@/utilities/commonFunctions'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VeeValidateProviderMode } from '@/Model/forms/types'
import { OfflinePaymentsAutoEnrollOptions, OfflinePaymentsOption } from '@/Model/payments/types'
import { ProviderInstance } from 'vee-validate/dist/types/types'
import ProgressButton from '@/commoncomponents/progressButton/progressButton'
import ChangeOfflinePaymentsModal from '@/popupcomponents/payments/ChangeOfflinePaymentsModal.vue'
import {
  checkoutNameRequiredValidator,
  maxCharsInputValidator
} from '@/validators/payments/validators'
import { createOfflinePayments, updateOfflinePayments } from '@/services/payments/api'
import { ToastType } from '@/Model/toastType'
import InputWithCharacterCounter from '@/ui-components/inputWithCharacterCounter/inputWithCharacterCounter.vue'

@Component({
  components: {
    OffCanvas,
    DropdownList,
    CurrencyInput,
    ValidationObserver,
    ValidationProvider,
    ProgressButton,
    ChangeOfflinePaymentsModal,
    InputWithCharacterCounter
  }
})
export default class OfflinePayments extends Vue {
  @Prop({ required: true }) offlinePaymentsOption: OfflinePaymentsOption | undefined // Type annotation for clarity

  @PropSync('isCustomizeOfflinePaymentsVisible', { type: Boolean, required: true })
  isCustomizeOfflinePaymentsVisibleSync!: boolean
  @Ref('formObserverRef') readonly formObserverRef?: InstanceType<typeof ValidationObserver>
  @Ref('nameObserverRef') readonly nameObserverRef?: ProviderInstance

  readonly screenText = new ScreenText()
  readonly currencyOptions: CurrencyInputOptions = APP_CONST.CURRENCY_OPTIONS
  readonly nameText = this.screenText.getScreenText('LABEL_NAME').toLowerCase()

  isValidationForProgress = false
  autoEnrollOptions = OfflinePaymentsAutoEnrollOptions
  validationMode = VeeValidateProviderMode
  isChangeOfflinePaymentsModalVisible = false
  isSavingOfflinePayments = false

  name: string = ''
  description: string = ''
  offlinePaymentsType: OfflinePaymentsAutoEnrollOptions = OfflinePaymentsAutoEnrollOptions.Enroll
  MAX_DESCRIPTION_LENGTH = 500

  get saveButtonText(): string {
    return this.screenText.getScreenText('BTN_SAVE')
  }

  created() {
    this.onCreated()
    if (this.offlinePaymentsOption) {
      this.name = this.offlinePaymentsOption.name
      this.description = this.offlinePaymentsOption.instructions
      this.offlinePaymentsType = this.offlinePaymentsOption.autoEnrollStatusId
    }
  }

  onCreated() {
    this.registerValidators()
  }

  onCancel() {
    this.onClose()
    this.isCustomizeOfflinePaymentsVisibleSync = false
  }

  onClose() {
    if (!this.offlinePaymentsOption) {
      this.name = ''
      this.description = ''
      this.resetInputs()
    } else {
      this.name = this.offlinePaymentsOption.name
      this.description = this.offlinePaymentsOption.instructions
    }
  }

  @Watch('offlinePaymentsOption')
  onOfflinePaymentsOptionChange() {
    if (this.offlinePaymentsOption) {
      this.name = this.offlinePaymentsOption.name
      this.description = this.offlinePaymentsOption.instructions
      this.offlinePaymentsType = this.offlinePaymentsOption.autoEnrollStatusId
    }
  }

  private async canSave(): Promise<boolean> {
    if (this.formObserverRef) {
      await this.formObserverRef.validate()
    }

    return !!this.formObserverRef && this.formObserverRef.flags.valid
  }

  private resetInputs() {
    if (this.formObserverRef) {
      this.formObserverRef.reset()
    }
  }

  async onSave() {
    if (!(await this.canSave())) {
      return
    }

    this.isValidationForProgress = false

    try {
      this.isSavingOfflinePayments = true
      if (this.offlinePaymentsOption) {
        // Edit entry
        await updateOfflinePayments({
          name: this.name,
          instructions: this.description,
          autoEnrollStatusId: this.offlinePaymentsType,
          id: this.offlinePaymentsOption.id
        })
      } else {
        // Create entry
        await createOfflinePayments({
          name: this.name,
          instructions: this.description,
          autoEnrollStatusId: this.offlinePaymentsType,
          accountID: APP_UTILITIES.getAccountId(),
          isEnabled: true
        })
      }
      APP_UTILITIES.showToastMessage('Offline payment saved', ToastType.Success)
    } catch {
      APP_UTILITIES.showToastMessage('Unexpected error!', ToastType.Error)
    } finally {
      this.OnSaveOfflinePayments()
      this.isSavingOfflinePayments = false
    }

    this.isValidationForProgress = false
    this.isCustomizeOfflinePaymentsVisibleSync = false
  }

  closeChangeOfflinePaymentsModal(): void {
    this.isChangeOfflinePaymentsModalVisible = false
  }

  confirmChangeOfflinePayments(): void {
    this.closeChangeOfflinePaymentsModal()
    this.onSave()
  }

  OnSaveOfflinePayments() {
    this.$emit('OnSaveOfflinePayments')
  }

  beforeOnSave() {
    if (this.offlinePaymentsOption) {
      if (
        (this.formObserverRef && this.formObserverRef.flags.changed) ||
        this.offlinePaymentsOption.autoEnrollStatusId !== this.offlinePaymentsType
      ) {
        this.isChangeOfflinePaymentsModalVisible = true
      } else {
        this.onCancel()
      }
    } else {
      this.onSave()
    }
  }

  handleDescriptionChange(value: string): void {
    this.description = value
  }

  private registerValidators() {
    checkoutNameRequiredValidator(
      this.screenText.getScreenText('REQUIRED_INPUT_FIELD_ERROR').replace('{field}', this.nameText)
    )

    maxCharsInputValidator()
  }
}
