var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OffCanvas',{attrs:{"isVisible":_vm.isBillingMethodMenuOpenComputed},on:{"update:isVisible":function($event){_vm.isBillingMethodMenuOpenComputed=$event},"update:is-visible":function($event){_vm.isBillingMethodMenuOpenComputed=$event},"onClose":_vm.onClose}},[_c('aside',{staticClass:"add-new-sec"},[_c('ValidationObserver',{ref:"formObserverRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.beforeOnSave)}}},[_c('div',{staticClass:"billing-method-container"},[_c('div',{staticClass:"billing-method-container__title uk-margin-small-bottom"},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText('LABEL_PAYMENT_METHOD_TITLE'))+" ")]),_c('div',{staticClass:"billing-method-container__description uk-margin-small-bottom"},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText('LABEL_PAYMENT_METHOD_DESCRIPTION'))+" ")]),_vm._l((_vm.paymentPlanOptionList),function(paymentItem,paymentIndex){return _c('div',{key:paymentItem.id,staticClass:"payment-plan-container"},[_c('div',{staticClass:"payment-plan-container__header"},[_c('p',{staticClass:"payment-plan-container__header-text"},[_vm._v("Payment plan "+_vm._s(paymentIndex + 1))]),(paymentIndex === 1)?_c('button',{staticClass:"payment-plan-container__header-delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleDeletePaymentPlan(paymentItem.id)}}},[_c('img',{staticClass:"payment-plan-container__header-img",attrs:{"src":require("../../../../assets/images/trash.svg")}})]):_vm._e()]),_c('div',{staticClass:"payment-plan-container__inputs"},[_c('div',{staticClass:"payment-plan-container__inputs-field"},[_c('div',{staticClass:"form-group uk-margin-small-bottom"},[_c('label',{staticClass:"uk-margin-remove"},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText("LABEL_NUMBER_OF_PAYMENTS"))),_c('span',{staticClass:"asterisk"},[_vm._v("*")])])]),_c('DropdownList',{class:{
                    'dropdown-list--error': paymentItem.selectedValue && paymentItem.selectedValue.isItDuplicated
                  },attrs:{"dropdown":paymentItem.numberOfPaymentsList,"autoSelectionAllowed":false},on:{"onSelectionChange":function($event){return _vm.handlePaymentPlanOptionChange($event, paymentItem.id, 'numberOfPayments')}}})],1),_c('div',{staticClass:"payment-plan-container__inputs-field"},[_c('div',{staticClass:"form-group uk-margin-small-bottom"},[_c('label',{staticClass:"uk-margin-remove"},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText("LABEL_PAYMENT_FREQUENCY"))),_c('span',{staticClass:"asterisk"},[_vm._v("*")])])]),_c('DropdownList',{class:{
                    'dropdown-list--error': paymentItem.selectedValue && paymentItem.selectedValue.isItDuplicated
                  },attrs:{"dropdown":paymentItem.frequencyList,"autoSelectionAllowed":false},on:{"onSelectionChange":function($event){return _vm.handlePaymentPlanOptionChange($event, paymentItem.id, 'frequency')}}})],1),(paymentItem.selectedValue.isItDuplicated || _vm.isPaymentPlanUnselected)?_c('div',{staticClass:"form-field-error uk-width-1-1 uk-padding-remove"},[_c('i',{staticClass:"fa-solid fa-exclamation-circle"}),_vm._v(" "+_vm._s(_vm.isPaymentPlanUnselected ? "Please select a valid payment plan" : _vm.screenText.getScreenText('LABEL_UNIQUE_PAYMENT_PLAN'))+" ")]):_vm._e(),(!paymentIndex && _vm.isAddPaymentPlanButtonVisible)?_c('div',{staticClass:"payment-plan-container__actions"},[_c('a',{staticClass:"payment-plan-container__add-payment-plan-btn",attrs:{"target":"_blank"}},[_c('button',{on:{"click":_vm.handleAddPaymentPlan}},[_c('span',[_c('i',{staticClass:"fa-solid fa-plus uk-margin-small-right"}),_c('span',{staticClass:"fee-button-text"},[_vm._v(_vm._s(_vm.screenText.getScreenText('LABEL_PAYMENT_PLAN')))])])])])]):_vm._e()])])}),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"uk-margin-small-bottom",attrs:{"for":"tax-rate-select","data-testid":"tax-rate"}},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText('LABEL_PAYMENT_EXCEPTIONS'))+" ")]),_c('div',{staticClass:"subheader"},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText('LABEL_PAYMENT_EXCEPTIONS_DESCRIPTION'))+" ")])])],2),_c('div',{staticClass:"form-group form-group--no-margin-bottom"},[_c('label',{staticClass:"uk-margin-small-bottom",attrs:{"for":"minimum-order-amount"}},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText("LABEL_MINIMUM_ORDER_AMOUNT"))+" ")])]),_c('div',{staticClass:"uk-width-1-2 uk-margin-bottom payment-plan-container__minimun-amount-container"},[_c('ValidationProvider',{attrs:{"rules":"min_value:1","mode":_vm.validationMode.Eager},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var invalid = ref.invalid;
                  var validated = ref.validated;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.minimumOrderAmount),expression:"minimumOrderAmount"}],staticClass:"payment-plan-container__inputs-decorator-input payment-plan-container__inputs-decorator-input--currency",attrs:{"id":"minimum-order-amount","type":"number","min":"1"},domProps:{"value":(_vm.minimumOrderAmount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.minimumOrderAmount=$event.target.value}}}),_c('span',{staticClass:"form-field-error",style:({
                  visibility: validated && invalid ? 'visible' : 'hidden'
                }),attrs:{"data-testid":"name-required-error"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('span',{staticClass:"payment-plan-container__inputs-decorator-content payment-plan-container__inputs-decorator-content--currency"})],1),_c('div',{staticClass:"payment-plan-deactivation-container uk-margin-medium-bottom"},[_c('div',{staticClass:"form-group form-group--no-margin-bottom"},[_c('label',{staticClass:"uk-margin-small-bottom",attrs:{"for":"payment-plan-value"}},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText("LABEL_TURN_OFF_PAYMENT_PLANS"))+" ")])]),_c('div',{staticClass:"payment-plan-deactivation-container payment-plan-deactivation-container__counter"},[_c('ValidationProvider',{attrs:{"rules":"min_value:1","mode":_vm.validationMode.Eager},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var invalid = ref.invalid;
                var validated = ref.validated;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.disablePaymentsCount),expression:"disablePaymentsCount"}],staticClass:"payment-plan-container__inputs-decorator-input",attrs:{"id":"payment-plan-value","type":"number","min":"1"},domProps:{"value":(_vm.disablePaymentsCount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.disablePaymentsCount=$event.target.value}}}),_c('span',{staticClass:"form-field-error",style:({
                    visibility: validated && invalid ? 'visible' : 'hidden'
                  })},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"payment-plan-deactivation-container payment-plan-deactivation-container__time-units"},[_c('DropdownList',{attrs:{"id":"payment-plan-deactivation-period","dropdown":_vm.paymentPlanDeactivationDropdown,"autoSelectionAllowed":false},on:{"onSelectionChange":_vm.handlePaymentPlanDeactivationChange},model:{value:(_vm.currentDeactivationPeriodSelected),callback:function ($$v) {_vm.currentDeactivationPeriodSelected=$$v},expression:"currentDeactivationPeriodSelected"}})],1)]),_c('div',[_c('button',{staticClass:"btn-secondary uk-margin-small-right",attrs:{"type":"button"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.screenText.getScreenText('BTN_CANCEL'))+" ")]),_c('ProgressButton',{attrs:{"bx-attr":"save-btn","buttonIndex":_vm.screenText.getScreenText('BTN_SAVE'),"buttonText":_vm.screenText.getScreenText('BTN_SAVE'),"disableButton":!_vm.isSaveDisabled}})],1)])]}}])})],1),_c('StateModal',{attrs:{"isVisible":_vm.isModalVisible,"config":_vm.modalConfig},on:{"onClose":_vm.toggleConfirmationModal,"onConfirm":_vm.handleConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }