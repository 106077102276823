import { extend } from 'vee-validate'
import { required, min_value, between, regex } from 'vee-validate/dist/rules'
import { ValidationRuleFunction } from 'vee-validate/dist/types/types'
import { percentToNumber } from '@/services/payments/helpers'

const percentTwoDecimalRegexPattern = /^([0-9]{1,3}|[0-9]{1,3}\.[0-9]?[0-9]?|\.[0-9]?[0-9])?%?$/

export const percentInputDecimalLengthValidator: ValidationRuleFunction = (value: string) =>
  regex.validate(value, { regex: percentTwoDecimalRegexPattern })

export const percentInputBetweenValueValidator: ValidationRuleFunction = (
  value: string,
  { args }: Record<string, any>
) => {
  const numValue = percentToNumber(value)
  return between.validate(numValue, args)
}

export function fixedAmountFeeMinValueValidator(message: string) {
  extend('fixed_amount_min_value', {
    ...min_value,
    message
  })
}

export function fixedAmountFeeRequiredValidator(message: string) {
  extend('fixed_amount_required', {
    ...required,
    message
  })
}

export function percentInputBetweenValidator(message: string) {
  extend('percent_input_between', {
    ...between,
    params: ['args'],
    message,
    validate: percentInputBetweenValueValidator
  })
}

export function feeNameRequiredValidator(message: string) {
  extend('name_required', {
    ...required,
    message
  })
}

export function customFeeSelectionRequiredValidator(message: string) {
  extend('fee_type_required', {
    ...required,
    message
  })
}

export function percentInputRequiredValidator(message: string) {
  extend('percent_input_required', {
    ...required,
    message
  })
}

export function percentInputTwoDecimalPlacesValidator(message: string) {
  extend('percent_input_decimal_length', {
    ...regex,
    message,
    validate: percentInputDecimalLengthValidator
  })
}

export function checkoutNameRequiredValidator(message: string) {
  extend('name_required', {
    ...required,
    message
  })
}

export function discountDescriptionRequiredValidator(message: string) {
  extend('description_required', {
    ...required,
    message
  })
}

export function maxCharsInputValidator() {
  extend('max_chars', {
    params: ['limit'],
    validate(value: string, params: any) {
      const limit = Array.isArray(params) ? params[0] : params.limit
      return {
        valid: value.length <= limit
      }
    }
  })
}
