




























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardComponent from "@/commoncomponents/CardComponent.vue";
import { ScreenText } from "@/lang/ScreenText";
import ToggleSwitch from "@/commoncomponents/ToggleSwitch.vue";
import APP_UTILITIES from "@/utilities/commonFunctions";
import { ToastType } from "@/Model/toastType";
import {  PaymentPlan } from "@/Model/payments/types";
import { toggleStatusPaymentPlan } from "@/services/payments/api";

@Component({
  components: {
    CardComponent,
    ToggleSwitch
  }
})
export default class PaymentPlanDetail extends Vue {
  enabled = false;
  // Initial flag to indicate first render state
  private _firstRender = true;
  disableSwitch = false;

  name = "";

  //TODO: this will be modified by Gilbert's changes, this will be a required prop
  @Prop({ required: false }) paymentPlanOptions: PaymentPlan | undefined;

  mounted() {
    this._firstRender = false;
    const data = this.paymentPlanOptions
      ? { ...this.paymentPlanOptions }.isActive
      : false;
    this.enabled = data;
  }

  readonly screenText = new ScreenText();

  toggleEditPaymentPlan() {
    this.$emit("OnEditPaymentPlan");
  }

  onStatusUpdated() {
    this.$emit("OnStatusUpdated");
  }

  @Watch("enabled")
  async onEnabledChanged() {
    if (
      !this._firstRender &&
      this.paymentPlanOptions &&
      this.paymentPlanOptions.isActive !== this.enabled
    ) {
      try {
        this.disableSwitch = true;
        if (this.paymentPlanOptions.id) {
          await toggleStatusPaymentPlan({
            isActive: this.enabled,
            id: this.paymentPlanOptions.id
          });
        }
        this.onStatusUpdated();
        APP_UTILITIES.showToastMessage(
          `Payment Plan is ${!this.enabled ? "inactive" : "active"}`,
          ToastType.Success
        );
      } catch {
        APP_UTILITIES.showToastMessage("Unexpected Error!", ToastType.Error);
        this.enabled = !this.enabled;
      } finally {
        this.disableSwitch = false;
      }
    }
  }
}
