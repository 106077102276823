

























































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import paymentsModule from '@/store/modules/Payments/module'
import OffCanvas from '@/commoncomponents/OffCanvas.vue'
import { ScreenText } from '@/lang/ScreenText'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ProviderInstance } from 'vee-validate/dist/types/types'
import { VeeValidateProviderMode } from '@/Model/forms/types'
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue'
import { DropdownListItem, DropdownListOptions } from '@/ui-components/dropdownListBx/types'
import ProgressButton from '@/commoncomponents/progressButton/progressButton'
import MultiParticipantRules from './MultiParticipantRules.vue'
import EarlyRegistration from './EarlyRegistration.vue'
import {
  Discount,
  MultiParticipantRule,
  EarlyRegistrationRule,
  DiscountType,
  AmountUnitType,
  TimeRangeType,
  StateModalType,
  DiscountRule
} from '@/Model/payments/types'
import {
  discountDescriptionRequiredValidator,
  maxCharsInputValidator
} from '@/validators/payments/validators'
import StateModal from '@/popupcomponents/StateModal.vue'
import { createAutomaticDiscount } from '@/services/payments/api'
import APP_UTILITIES from '@/utilities/commonFunctions'
import { ToastType } from '@/Model/toastType'
import InputWithCharacterCounter from '@/ui-components/inputWithCharacterCounter/inputWithCharacterCounter.vue'

type CustomDropdownOptions = DropdownListOptions & { ruleAmount: string }

@Component({
  components: {
    OffCanvas,
    ValidationObserver,
    ValidationProvider,
    DropdownList,
    ProgressButton,
    MultiParticipantRules,
    EarlyRegistration,
    StateModal,
    InputWithCharacterCounter
  }
})
export default class DiscountConfiguration extends Vue {
  @Ref('formDiscountObserverRef')
  readonly formDiscountObserverRef?: InstanceType<typeof ValidationObserver>
  @Ref('nameObserverRef') readonly nameObserverRef?: ProviderInstance
  @Ref('descriptionObserverRef')
  readonly descriptionObserverRef?: ProviderInstance

  readonly screenText = new ScreenText()
  readonly MULTI_PARTICIPANT_INITIAL_SEQUENCE_NUMBER = 2
  readonly MULTI_PARTICIPANT_MAX_SEQUENCE_NUMBER = 10
  readonly discountUnitType = AmountUnitType
  readonly discountType = DiscountType

  validationMode = VeeValidateProviderMode
  name: string = ''
  description: string = ''
  discountUnitAmount: AmountUnitType = AmountUnitType.PERCENT_RATE
  isSaveDisabled = false
  DISCOUNT_APPLICATION_SCOPE = 'Account'
  formRef = this.$refs.discountForm as HTMLFormElement
  isUnitResetNeeded = false
  SELECT_TYPE_VALUE = -1
  isModalVisible = false
  modalConfig = {
    title: this.screenText.getScreenText('LABEL_CHANGE_DISCOUNT_TITLE'),
    description: this.screenText.getScreenText('LABEL_CHANGE_DISCOUNT_DESCRIPTION'),
    type: StateModalType.WARNING,
    cancelButtonLabel: this.screenText.getScreenText('BTN_GO_BACK_CAPS'),
    confirmButtonLabel: this.screenText.getScreenText('BTN_SAVE')
  }
  isSelectInvalid = false

  // This is the model that contains the rule list when the discount type is "Multi-participant"
  rules: CustomDropdownOptions[] = []

  /**
   * Early Registration type models
   * The following are the models for:
   * - early discount ends input
   * - session start (hold by earlyDiscountListOptions) dropdown list
   * - early amount value input
   */
  earlyDiscountEnds: number | null = null
  earlyAmountValue: number | null = null

  earlyDiscountListOptions: DropdownListItem[] = [
    { id: TimeRangeType.MONTHS, value: 'Months before session starts' },
    { id: TimeRangeType.DAYS, value: 'Days before session starts' },
    { id: TimeRangeType.WEEKS, value: 'Weeks before session starts' }
  ]

  discountRuleListDropdown: DropdownListOptions = {
    id: this.earlyDiscountListOptions[0].id,
    value: this.earlyDiscountListOptions[0].value,
    singleSelect: true,
    showSelectLabel: false,
    dropdownList: this.earlyDiscountListOptions
  }

  typeListOptions: DropdownListItem[] = [
    { id: this.SELECT_TYPE_VALUE, value: 'Select' },
    { id: DiscountType.EARLY_REGISTRATION, value: 'Early Registration' },
    { id: DiscountType.MULTI_PARTICIPANT, value: 'Multi-participant' }
  ]

  currentTypeSelection: DropdownListItem = this.typeListOptions[0]

  typeListDropdown: DropdownListOptions = {
    id: this.typeListOptions[0].id,
    value: this.typeListOptions[0].value,
    singleSelect: true,
    showSelectLabel: true,
    dropdownList: this.typeListOptions
  }

  MAX_DESCRIPTION_LENGTH = 500

  /**
   * Called when the component is created.
   */
  created(): void {
    discountDescriptionRequiredValidator(
      this.screenText.getScreenText('LABEL_DISCOUNT_DESCRIPTION_REQUIRED')
    )

    maxCharsInputValidator()
  }

  /**
   * Called before the component is mounted.
   */
  beforeMount(): void {
    this.rules = this.initMultiParticipantRuleList()
  }

  // Watch change on type selection to keep track of validation for selected type
  @Watch('currentTypeSelection', { deep: true })
  currentTypeValid(): void {
    const isTypeValid = this.currentTypeSelection.id !== this.SELECT_TYPE_VALUE

    if (
      this.formDiscountObserverRef &&
      this.formDiscountObserverRef.flags &&
      this.formDiscountObserverRef.flags.touched
    ) {
      this.isSelectInvalid = !isTypeValid
    }
  }

  /**
   * Watches changes to the discount unit amount.
   * @param {AmountUnitType} newValue - The new discount unit amount.
   */
  @Watch('discountUnitAmount', { deep: true })
  discountUnitAmountReference(): void {
    if (
      this.currentTypeSelection.id === DiscountType.MULTI_PARTICIPANT &&
      (this.isUnitResetNeeded || !this.discountListComputed.length)
    ) {
      this.cleanMultiParticipantAmounts()
    } else if (this.isUnitResetNeeded) {
      this.earlyAmountValue = null
    }

    this.isUnitResetNeeded = true
  }

  toggleConfirmationModal() {
    this.isModalVisible = !this.isModalVisible
    if (!this.isModalVisible) {
      paymentsModule.setIsConfiguratioDiscountMenuOpen(true)
    }
  }

  /**
   * Watches changes to the discount menu open state.
   * @param {boolean} isMenuOpen - Whether the discount menu is open.
   */
  @Watch('isConfigurationDiscountMenuOpenComputed', { deep: true })
  isDiscountMenuOpen(isMenuOpen: boolean): void {
    const currentDiscountItem = paymentsModule.getSelectedDiscount

    if (isMenuOpen && currentDiscountItem !== null) {
      this.setExistingDiscountValues()
    } else if (isMenuOpen && currentDiscountItem === null && this.discountListComputed.length) {
      if (this.discountListComputed[0].discountTypeId === DiscountType.MULTI_PARTICIPANT) {
        this.typeListDropdown.id = this.typeListOptions[DiscountType.MULTI_PARTICIPANT].id
        this.typeListDropdown.value = this.typeListOptions[DiscountType.MULTI_PARTICIPANT].value
        this.currentTypeSelection = this.typeListOptions[DiscountType.MULTI_PARTICIPANT]
      } else {
        this.typeListDropdown.id = this.typeListOptions[DiscountType.EARLY_REGISTRATION].id
        this.typeListDropdown.value = this.typeListOptions[DiscountType.EARLY_REGISTRATION].value
        this.currentTypeSelection = this.typeListOptions[DiscountType.EARLY_REGISTRATION]
      }
      Vue.set(this.typeListDropdown, 'disable', true)
    } else {
      Vue.set(this.typeListDropdown, 'disable', false)

      this.typeListDropdown = {
        id: this.typeListOptions[0].id,
        value: this.typeListOptions[0].value,
        singleSelect: true,
        showSelectLabel: true,
        dropdownList: this.typeListOptions
      }
    }
  }

  /**
   * Sets the existing discount values.
   */
  setExistingDiscountValues(): void {
    const currentDiscountItem = paymentsModule.getSelectedDiscount
    if (currentDiscountItem) {
      const targetDiscountTypeSelection = this.typeListOptions.find(
        discountItem => discountItem.id === currentDiscountItem.discountTypeId
      )

      if (currentDiscountItem != null && targetDiscountTypeSelection) {
        this.name = currentDiscountItem.name
        this.description = currentDiscountItem.description
        this.currentTypeSelection.id = targetDiscountTypeSelection.id
        this.currentTypeSelection.value = targetDiscountTypeSelection.value
        this.typeListDropdown.id = this.currentTypeSelection.id
        this.typeListDropdown.value = this.currentTypeSelection.value
        this.isUnitResetNeeded = false
        this.discountUnitAmount = String(currentDiscountItem.amountUnitType) as AmountUnitType

        if (currentDiscountItem.discountTypeId === DiscountType.MULTI_PARTICIPANT) {
          this.rules = this.initMultiParticipantRuleList(
            (currentDiscountItem.rules as MultiParticipantRule[])[0].participants,
            currentDiscountItem.rules
          )
        } else {
          const earlyRegistrationEntry = (currentDiscountItem.rules as [EarlyRegistrationRule])[0]
          this.earlyDiscountEnds = earlyRegistrationEntry.timeRangeUnit
          this.earlyAmountValue = earlyRegistrationEntry.amount
          this.discountRuleListDropdown.id = earlyRegistrationEntry.timeRangeType
          this.discountRuleListDropdown.value = `${
            this.earlyDiscountListOptions[earlyRegistrationEntry.timeRangeType - 1].value
          }`
        }
        // disable dropdown menu since type is not editable
        Vue.set(this.typeListDropdown, 'disable', true)
      }
    }
  }

  /**
   * Handles cancel action.
   */
  onCancel(): void {
    this.onClose()
    paymentsModule.setIsConfiguratioDiscountMenuOpen(false)
  }

  /**
   * Handles close action.
   */
  onClose(): void {
    this.resetInputs()
  }

  /**
   * Handles selection of discount type.
   * @param {DropdownListItem} selection - The selected discount type.
   */
  onSelectType(selection: DropdownListItem): void {
    if (selection.id === DiscountType.MULTI_PARTICIPANT) {
      this.onResetEarlyRegistrationDiscount()
    } else {
      this.onResetMultiParticipantRules()
    }
    this.currentTypeSelection = selection
  }

  /**
   * Initializes the multi-participant rule list.
   * @param {number} [sequenceStartNumber=this.MULTI_PARTICIPANT_INITIAL_SEQUENCE_NUMBER] - The starting sequence number.
   * @param {MultiParticipantRule[] | EarlyRegistrationRule} [discountRules] - The discount rules.
   * @returns {CustomDropdownOptions[]} The initialized rule list.
   */
  initMultiParticipantRuleList(
    sequenceStartNumber: number = this.MULTI_PARTICIPANT_INITIAL_SEQUENCE_NUMBER,
    discountRules?: MultiParticipantRule[] | [EarlyRegistrationRule]
  ): CustomDropdownOptions[] {
    const participantCounterList: DropdownListItem[] = Array.from(
      { length: this.MULTI_PARTICIPANT_MAX_SEQUENCE_NUMBER - 1 },
      (_, index) => ({
        id: index + sequenceStartNumber,
        value: `${index + sequenceStartNumber}`
      })
    )

    const rawRuleStructure = {
      singleSelect: true,
      showSelectLabel: true,
      dropdownList: participantCounterList,
      error: false,
      errorText: ''
    }

    if (discountRules) {
      const targetDiscountRules = (discountRules as MultiParticipantRule[]).map(
        (item: MultiParticipantRule): CustomDropdownOptions => {
          // Disable the dropdown list if there are more than one discount rule
          // if only one rule is present, the dropdown list should be enabled since is the start of the sequence
          const isDiscountRulesDisabled = discountRules.length > 1

          return {
            ...rawRuleStructure,
            id: item.participants,
            value: `${item.participants}`,
            ruleAmount: `${item.amount}`,
            disable: isDiscountRulesDisabled
          }
        }
      )
      return targetDiscountRules
    }

    return [
      {
        ...rawRuleStructure,
        id: sequenceStartNumber,
        value: `${sequenceStartNumber}`,
        ruleAmount: '',
        disable: false
      }
    ]
  }

  /**
   * Getter for rules.
   * @returns {CustomDropdownOptions[]} The rules.
   */
  get rulesVal(): CustomDropdownOptions[] {
    return this.rules
  }

  /**
   * Setter for rules.
   * @param {CustomDropdownOptions[]} newRules - The new rules.
   */
  set rulesVal(newRules: CustomDropdownOptions[]) {
    this.rules = newRules
  }

  /**
   * Getter for the computed discount list.
   * @returns {Discount[]} The discount list.
   */
  get discountListComputed(): Discount[] {
    return paymentsModule.discountList
  }

  /**
   * Getter for whether the configuration discount menu is open.
   * @returns {boolean} Whether the configuration discount menu is open.
   */
  get isConfigurationDiscountMenuOpenComputed(): boolean {
    return paymentsModule.isConfigurationDiscountMenuOpen
  }

  /**
   * Setter for whether the configuration discount menu is open.
   * @param {boolean} value - The new value.
   */
  set isConfigurationDiscountMenuOpenComputed(value: boolean) {
    paymentsModule.setIsConfiguratioDiscountMenuOpen(value)
  }

  /**
   * Handles percentage update for a rule.
   * @param {CustomDropdownOptions} rule - The rule being updated.
   */
  onPercentageUpdate(rule: CustomDropdownOptions): void {
    // Validate that percentage amount doesn't go over 100%
    if (Number(rule.ruleAmount) > 100) {
      const ruleItem = this.rules.find(val => val.id === rule.id)
      if (ruleItem) {
        ruleItem.ruleAmount = '100'
      }
    }
  }

  /**
   * Adds a multi-participant rule.
   */
  onAddMultiParticipantRule(): void {
    const lastRuleItem = [...this.rules].pop() as CustomDropdownOptions
    const filteredDropdownItems = lastRuleItem.dropdownList.filter(
      rule => Number(rule.value) > Number(lastRuleItem.value)
    )

    // We create the new rule item following the sequence of the first selected item and adding one new entry
    const ruleItemValue = Number(lastRuleItem.value) + 1
    const newRule = {
      id: ruleItemValue,
      singleSelect: true,
      showSelectLabel: true,
      value: `${ruleItemValue}`,
      dropdownList: filteredDropdownItems,
      error: false,
      errorText: '',
      ruleAmount: '',
      disable: true
    }

    // After a new rule has been added ,the initial rule needs to be disabled to ensure that we will follow the sequence
    this.rules[0].disable = true

    this.rules.push(newRule)
  }

  /**
   * Cleans the multi-participant amounts.
   */
  cleanMultiParticipantAmounts(): void {
    this.rules = this.rules.map(value => {
      return { ...value, ruleAmount: '' }
    })
  }

  /**
   * Removes a multi-participant rule.
   */
  onRemoveMultiParticipantRule(): void {
    const updatedRules = [...this.rules]
    updatedRules.pop()
    this.rules = updatedRules

    // If we remove all the rules we enable the first rule so we can select again the starting point from the sequence
    if (this.rules.length === 1) {
      this.rules[0].disable = false
    }
  }

  /**
   * Handles change in discount end value.
   * @param {number} value - The new discount end value.
   */
  onChangeDiscountEnd(value: number): void {
    this.earlyDiscountEnds = value
  }

  /**
   * Handles change in session start.
   * @param {DropdownListItem} sessionStartItemReference - The session start item.
   */
  onChangeSessionStart(sessionStartItemReference: DropdownListItem): void {
    this.discountRuleListDropdown.id = sessionStartItemReference.id
    this.discountRuleListDropdown.value = `${sessionStartItemReference.value}`
  }

  /**
   * Resets the multi-participant rules.
   */
  onResetMultiParticipantRules(): void {
    this.rules = this.initMultiParticipantRuleList()
  }

  /**
   * Resets the early registration discount.
   */
  onResetEarlyRegistrationDiscount(): void {
    this.discountRuleListDropdown.id = this.earlyDiscountListOptions[0].id
    this.discountRuleListDropdown.value = this.earlyDiscountListOptions[0].value
    this.earlyDiscountEnds = null
    this.earlyAmountValue = null
  }

  /**
   * Resets the input fields.
   * @private
   */
  private resetInputs(): void {
    this.typeListDropdown.id = this.typeListOptions[0].id
    this.typeListDropdown.value = this.typeListOptions[0].value
    this.name = ''
    this.description = ''
    this.isUnitResetNeeded = false
    this.discountUnitAmount = AmountUnitType.PERCENT_RATE
    this.currentTypeSelection = this.typeListOptions[0]
    this.rules = this.initMultiParticipantRuleList()
    if (paymentsModule.getSelectedDiscount) {
      paymentsModule.SET_SELECTED_AUTOMATIC_DISCOUNT(null)
    }

    // reset form rules and associated form logic to prevent unnecesary error messages
    if (this.formDiscountObserverRef) {
      this.formDiscountObserverRef.reset()
      this.formDiscountObserverRef.flags.touched = false
      this.isSelectInvalid = false
    }

    this.onResetEarlyRegistrationDiscount()
  }

  async beforeOnSave(): Promise<void> {
    /*
      this is the way the VeeValidate package triggers the form validation
      reference taken from: https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#validate-before-submit
    */

    const isFormDiscountValid =
      this.formDiscountObserverRef && (await this.formDiscountObserverRef.validate())

    // only submit save if type selected is valid
    const isTypeValid = this.currentTypeSelection.id !== this.SELECT_TYPE_VALUE

    this.isSelectInvalid = !isTypeValid

    if (isFormDiscountValid && isTypeValid) {
      /**
       * this verifies that we are currently updating an existing discount, if it is true, a confirmation modal is shown
       * otherwise it saves the form directly
       */

      const currentDiscountItem = paymentsModule.getSelectedDiscount

      if (currentDiscountItem) {
        this.toggleConfirmationModal()
      } else {
        this.onSave()
      }
    }
  }

  /**
   * Confirmation logic when editing a discount
   */
  handleConfirm(): void {
    this.toggleConfirmationModal()
    const currentDiscountItem = paymentsModule.getSelectedDiscount

    if (currentDiscountItem) {
      this.onUpdate(currentDiscountItem)
    }
  }

  /**
   * Saves the discount.
   */
  async onSave(): Promise<void> {
    const currentActiveRules = this.getActiveRules()
    const accountId = this.getAccountId()

    const requestDiscountObj: Omit<Discount, 'id' | 'isActive'> = {
      name: this.name,
      discountTypeId: this.currentTypeSelection.id,
      amountUnitType: this.discountUnitAmount,
      appliesTo: this.DISCOUNT_APPLICATION_SCOPE,
      description: this.description,
      rules: currentActiveRules,
      accountId: accountId
    }

    try {
      this.isSaveDisabled = true
      await createAutomaticDiscount(requestDiscountObj)
      paymentsModule.setIsConfiguratioDiscountMenuOpen(false)
      await paymentsModule.fetchAutomaticDiscountsByAccount()
    } catch (error) {
      APP_UTILITIES.showToastMessage('Unexpected Error!', ToastType.Error)
    } finally {
      this.isSaveDisabled = false
    }
  }

  /**
   * Updates the discount.
   */
  async onUpdate(selectedDiscount: Discount): Promise<void> {
    const currentActiveRules = this.getActiveRules()

    const accountId = this.getAccountId()

    const requestDiscountObj: Discount = {
      name: this.name,
      discountTypeId: this.currentTypeSelection.id,
      amountUnitType: this.discountUnitAmount,
      appliesTo: this.DISCOUNT_APPLICATION_SCOPE,
      description: this.description,
      rules: currentActiveRules,
      accountId: accountId,
      id: selectedDiscount.id,
      isActive: selectedDiscount.isActive
    }

    try {
      this.isSaveDisabled = true
      paymentsModule.updateAutomaticDiscount(requestDiscountObj)
    } catch (error) {
      APP_UTILITIES.showToastMessage('Unexpected Error!', ToastType.Error)
    } finally {
      this.isSaveDisabled = false
    }
  }

  getActiveRules(): DiscountRule {
    let currentActiveRules: DiscountRule
    if (this.currentTypeSelection.id === DiscountType.MULTI_PARTICIPANT) {
      currentActiveRules = this.rules.map(
        (item: CustomDropdownOptions): MultiParticipantRule => {
          return {
            participants: parseInt(item.value),
            amount: parseInt(item.ruleAmount)
          }
        }
      )
    } else {
      currentActiveRules = [
        {
          timeRangeUnit: this.earlyDiscountEnds || 1,
          timeRangeType: parseInt(String(this.discountRuleListDropdown.id)),
          amount: this.earlyAmountValue || 1
        }
      ]
    }
    return currentActiveRules
  }

  getAccountId(): number {
    const accountIdCookie = APP_UTILITIES.getCookie('accountId')
    const accountId = accountIdCookie ? Number.parseInt(accountIdCookie) : 0
    return accountId
  }

  handleDescriptionChange(value: string): void {
    this.description = value
  }
}
